<template>
    <div class="detail-main">
        <div class="article-header">
            <h2>卡牌清单</h2>
        </div>
        <div>
            <el-divider></el-divider>
        </div>
        <div class="article-nnav">
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ path: '/' }"><i class="el-icon-s-home"></i></el-breadcrumb-item>
                <el-breadcrumb-item>卡牌检索</el-breadcrumb-item>
                <el-breadcrumb-item>{{ cardInfo.name_cn }}</el-breadcrumb-item>
            </el-breadcrumb>
        </div>
        <div class="card-title">
            <h2>[{{ cardInfo.card_versions[0].packs[0].short_name }}] “{{ cardInfo.card_versions[0].packs[0].name_cn }}”
            </h2>
            <el-divider></el-divider>
        </div>
        <div class="card-info">
            <div class="card-img">
                <img :src="cardInfo.card_versions[0].image_url + '?x-oss-process=image/resize,h_600,w_600'">
            </div>
            <div class="card-text">
                <h3>{{ cardInfo.name_cn }}</h3>
                <el-divider></el-divider>
                <div class="card-attr">
                    <span>{{ cardInfo.unit_type }}</span>
                    <span>{{ cardInfo.nation }}</span>
                    <span>{{ cardInfo.race }}</span>
                    <span>{{ cardInfo.grade }}级</span> <br>
                    <span>力量:{{ cardInfo.power }}</span>
                    <span>☆值:{{ cardInfo.critical }}</span>
                    <span v-if="cardInfo.shield == '-'">护盾:{{ cardInfo.shield }}</span>
                    <span v-if="cardInfo.shield >= 0">护盾:{{ cardInfo.shield }}</span>
                    <span v-if="!cardInfo.shield">护盾:-</span>
                    <span>{{ cardInfo.skill }}</span>
                    <span v-if="cardInfo.gift">{{ cardInfo.gift }}</span>
                    <span v-if="cardInfo.trigger">{{ cardInfo.trigger }}</span>
                </div>
                <div class="card-ability" v-html="formatAbility(cardInfo.ability)">
                </div>
                <div class="card-flavor">{{ cardInfo.card_versions[0].flavor }}</div>
                <div class="card-attr2">
                    <span v-if="cardInfo.rule == 'D'">标准</span>
                    <span v-if="cardInfo.rule == 'V'">V标准</span>
                    <span v-if="cardInfo.rule == 'O'">其他</span>
                    <span>{{ cardInfo.card_versions[0].packs[0].short_name }}</span>
                    <span>{{ cardInfo.card_versions[0].rarity }}</span>
                    <span>{{ cardInfo.card_versions[0].illustrator }}</span>
                </div>
            </div>
        </div>
        <div class="m-clear-both"></div>

        <!--总览跳转-->
        <div class="back-search">
            <div class="back-search-result m-a-none">
                <router-link :to="{ path: '/index/cardList', query: { searchBack: true } }"><i
                        class="el-icon-caret-right"></i>返回搜索结果列表</router-link>
            </div>
        </div>
        <div class="card-log m-clear-both">
            <div>记录产品</div>
            <div class="card-log-desc">
                <div>
                    {{
                        $moment(cardInfo.card_versions[0].packs[0].released_at).format("YYYY-MM-DD")
                    }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ cardInfo.card_versions[0].packs[0].short_name }}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    {{ cardInfo.card_versions[0].packs[0].name_cn }}
                </div>
                <div>
                    <router-link :to="{ path: '/index/cardList', query: { searchBack: true } }">卡片清单</router-link>
                    <router-link v-if="cardInfo.product"
                        :to="{ path: '/index/productInfo', query: { id: cardInfo.product.id } }">产品页面</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "detail",
    data() {
        return {
            id: this.$route.query.id,
            cardInfo: {},
        }
    },
    methods: {
        getInfo() {
            let id = this.id;
            this.$axios.post("site/siteCard/getInfo", { id })
                .then((res) => {
                    if (res.data.code == 1000) {
                        this.cardInfo = res.data.data;
                    } else {
                        this.$message.error(res.data.msg)
                    }
                })
        },
        formatAbility(str) {
            if (!str) {
                return "";
            }
            str = str.replaceAll("【自】", "<br>【自】");
            str = str.replaceAll("【起】", "<br>【起】");
            str = str.replaceAll("【永】", "<br>【永】");
            if (str.indexOf("<br>") == 0) {
                str = str.replace("<br>", "");
            }
            return str;
        }
    },
    created() {
        this.getInfo();
    }
}
</script>

<style scoped>
.back-search {
    margin: 0 auto;
    padding: 20px 0;
}

.back-search-result>a {
    display: block;
    height: 58px;
    width: 280px;
    text-decoration: none;
    margin: 0 auto;
    border: 1px solid #404040;
    line-height: 58px;
    letter-spacing: 2px;
    font-size: 19px;
    font-weight: bold;
}

.back-search-result>a:link {
    color: #636b6f;
}

.card-flavor {
    height: 46px;
    line-height: 46px;
    color: white;
    background-color: #111111;
    text-align: center;
    font-size: 18px;
    margin-top: 5px;
}

.detail-main {
    background-color: white;
    text-align: center;
}

.article-header {
    text-align: center;
    padding-top: 20px;
}

.article-nnav {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;
}

.card-title {
    color: #434343;
    text-align: center;
}

.card-info {
    width: 100%;
    margin: 0 auto;
    height: auto;
}

.card-img {
    width: 100%;
    margin: 0 auto;
}

.card-img img {
    width: 80%;
}

.card-text {
    width: 100%;
}

.card-ability {
    background-color: #f0f0f0;
    padding: 10px;
    text-align: left;
}

.card-attr {
    line-height: 16px;
    padding-bottom: 10px;
    font-size: 16px;
    color: #c5c5c5;
    text-align: left;
    width: 100%;
}

.card-attr span {
    display: inline-block;
    text-align: center;
    color: #111111;
    border-right: 2px solid #c5c5c5;
    padding: 0 35px;
}

.card-attr>span:last-child {
    border-right: none;
}

.card-attr2 {
    line-height: 24px;
    padding-bottom: 15px;
    font-size: 16px;
    color: #c5c5c5;
    margin-top: 10px;
}

.card-attr2 span {
    display: inline-block;
    text-align: center;
    color: #111111;
    border-right: 2px solid #c5c5c5;
    padding: 0 10px;
}

.card-attr2 span:last-child {
    border-right: none;
}


.card-log {
    width: 100%;
    margin: 0 auto;
}

.card-log>div:first-child {
    height: 40px;
    background-color: #111111;
    color: #ffffff;
    line-height: 40px;
    font-weight: bold;
    padding-left: 10px;
}

.card-log>div:last-child {
    height: auto;
    border: 2px solid red;
    padding: 2px;
    line-height: 50px;
    background-color: #d9efff;
}

.card-log-desc>div {
    display: inline-block;
}

.card-log-desc>div:first-child {
    width: 100%;
    text-align: left;
}

.card-log-desc>div:last-child {
    width: 100%;
    text-align: left;
}

.card-log-desc>div:last-child a {
    display: inline-block;
    background-color: #008be0;
    color: white;
    border: none;
    height: 40px;
    margin: 0 5px;
    width: 100px;
    text-decoration: none;
    text-align: center;
    line-height: 40px;
}
</style>
